import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Container } from 'styled-bootstrap-grid';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import theme from '../../styles/theme';
import SocialLinks from "../core/SocialLinks";
import ImageBlock from "./ImageBlock";

const ArticleContentContainer = styled(Container)`
    margin-top: 0px;
    padding-top: 23px;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;
    margin: auto;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-bottom: 120px;
    } 
    @media(min-width: ${theme.breakpoints.large}) {
        padding-top: 0px;
        margin-top: -55px;
        padding-left: 164px;
        padding-right: 164px;
        flex-direction: row;
    }
`;

const RelatedArticlesContainer = styled.div`
    background: ${theme.colors.black};
    width: calc(100% + 70px);
    max-width: none;
    margin: auto;
    margin-left: -35px;
    margin-right: -35px;
    margin-top: 0px;
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    padding-top: 65px;
    padding-bottom: 30px;
    @media(min-width: ${theme.breakpoints.large}) {
        margin: auto;
        margin-top: 0px;
        margin-right: -164px;
        box-sizing: border-box;
        padding-top: 135px;
        padding-bottom: 120px;
        padding-left: 110px;
        padding-right: 163px;
        // width: 40%;
        width: 547px;
    }
    
`;

const RelatedArticlesTitle = styled.div`
    color: ${theme.colors.white};
    ${fonts.MaisonNeueDemi};
    font-size: 16px;
    line-height: 19.2px;
    padding-bottom: 35px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 27px;
        padding-bottom: 45px;
    }
`;

const RelatedArticle = styled.div`
    padding-bottom: 30px;
    // max-width: 230px;
    width: 100%; 
    @media(min-width: ${theme.breakpoints.medium}) {
        width: 33%; 
        padding-left: 10px;
        padding-right: 10px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        width: 100%; 
    }
`;

const RelatedArticleImageContainer = styled.div`
    height: 225px;
    width: 100%;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 165px;
    }
`;

const RelatedArticleImg = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const RelatedArticleTitle = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.white};
    font-size: 16px;
    line-height: 20px;
    padding-right: 30px;
    padding-top: 20px;
    max-width: 375px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 21.6px;
        padding-top: 20px;
        padding-right: 0px;
    }
`;

const ContentContainer = styled.div`
    width: 100%;
    padding-top: 0px;
    padding-bottom: 65px;
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.black};
    font-size: 18px;
    line-height: 28px;
    @media(min-width: ${theme.breakpoints.large}) {
        width: 60%;
        padding-top: 113px;
        padding-right: 40px;
    }
`;

const SocialIcons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    box-sizing: border-box;
    padding-top: 35px;
    @media(min-width: ${theme.breakpoints.medium}) {
        width: 100%;
    }
`;

const SocialShare = styled.div`
    ${fonts.MaisonNeueDemi};
    font-size: 18px;
    line-height: 21.6px;
    color: ${theme.colors.black};
    border-bottom: 2px solid ${theme.colors.black};
    margin-right: 30px;
`;

const ArticleText = styled.div`
    ${fonts.MaisonNeueLight};
    color: ${theme.colors.black};
    font-size: 16px;
    line-height: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
    
    p {
      margin-bottom: 12px;
    }
    
    b {
      ${fonts.MaisonNeueDemi};
    }
    
    ul {
      margin-left: 20px;
    
      li {
        list-style: disc;
      }
    }
    
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
        padding-top: 32px;
        padding-bottom: 32px;
    }
`;

const RelatedArticleList = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    @media(min-width: ${theme.breakpoints.medium}) {
        flex-direction: row; 
        align-items: flex-start;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        flex-direction: column; 
    }
`;

const ArticleContent = ({ blocks, articles }) => {

    let pageBlocks = [];

    blocks.forEach((block, index) => {
        let blockType = block.__typename;

        if (blockType === 'ContentfulBlockImage') {
            pageBlocks.push(<ImageBlock key={index} image={block.image && block.image} videoUrl={block.videoUrl && block.videoUrl}/>);
        }

        if (blockType === 'ContentfulBlockText') {
            pageBlocks.push(<ArticleText key={index}>{documentToReactComponents(block.content.json)}</ArticleText>);
        }
    });

    return (
        <ArticleContentContainer>
            <ContentContainer>
                {pageBlocks}
                <SocialIcons>
                    <SocialShare>share</SocialShare>
                    <SocialLinks black={"true"}/>
                </SocialIcons>
            </ContentContainer>
            <RelatedArticlesContainer className="cursor-white">
                <RelatedArticlesTitle>Related Articles</RelatedArticlesTitle>
                <RelatedArticleList>
                    {articles.map((article) => {
                        return (
                            <RelatedArticle key={article.node.slug}>
                                <Link to={`/article/${article.node.slug}`}>
                                    <RelatedArticleImageContainer>
                                        <RelatedArticleImg fluid={article.node.thumbnail.fluid} alt={article.node.title} />
                                    </RelatedArticleImageContainer>
                                </Link>
                                <RelatedArticleTitle>{article.node.title}</RelatedArticleTitle>
                            </RelatedArticle>
                        )
                    })}
                </RelatedArticleList>
            </RelatedArticlesContainer>
        </ArticleContentContainer>
    )
};

export default ArticleContent;
