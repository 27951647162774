import React from "react";
import styled from "styled-components";
import theme from "../../styles/theme";
import Img from 'gatsby-image';

const ImageContainer = styled.div`
    margin-top: 22px;
    margin-bottom: 22px;
    max-height: 220px;
    
    @media(min-width: ${theme.breakpoints.medium}) {
        margin-top: 32px;
        margin-bottom: 32px;
        max-height: 508px;
    }
    div {
        max-height: 220px;
        @media(min-width: ${theme.breakpoints.medium}) {
            max-height: 508px;
        }
    }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 22px;
  margin-top: 22px;
  max-height: 220px;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media(min-width: ${theme.breakpoints.medium}){
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const ImageBlock = ({image, videoUrl}) => {

    return (
        <div>
            {videoUrl ?
                <VideoContainer>
                    <iframe src={`${videoUrl}?autoplay=1&muted=1&background=1&title=0&byline=0&portrait=0&color=ffffff`}
                            title="Project Video"
                            frameBorder="0"
                            allow="fullscreen; autoplay"
                            allowFullScreen={true}/>
                </VideoContainer>
                :
                <ImageContainer>
                    <Img fluid={image.fluid}/>
                </ImageContainer>
            }
        </div>
    )
};

export default ImageBlock