import React, { Component } from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import SEO from '../components/Seo';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import get from 'lodash/get'
import ArticleHero from '../components/article/ArticleHero';
import ArticleContent from '../components/article/ArticleContent';
import theme from '../styles/theme';

const LayoutStyled = styled(Layout)`
  margin: 0;
`;

const WhiteBackground = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  box-sizing: border-box;
`;

class ProjectTemplate extends Component {
  render() {
    const path = this.props.location.pathname;
    const article = get(this, 'props.data.contentfulArticle');
    let articles = get(this, 'props.data.allContentfulArticle.edges');
    let blocks = article.contentBlocks;

    if (!blocks) {
      blocks = [];
    }

    articles = articles.filter(art => article.title !== art.node.title);

    return (
      <LayoutStyled colorScheme="dark">
        <SEO title={article.title}
          fullTitle={true}
          description={documentToPlainTextString(article.intro.text)}
          path={path} />
        <WhiteBackground>
          <ArticleHero title={article.title} intro={documentToReactComponents(article.intro.json)} image={article.heroImage.fluid} slug={article.slug}/>
          <ArticleContent blocks={blocks} articles={articles.slice(0,3)}/>
        </WhiteBackground>
      </LayoutStyled>
    )
  }
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    contentfulArticle(slug: {eq: $slug}) {
      title
      slug
      category {
        id
        name
      }
      intro {
        json
      }
      publishedDate(formatString:"DD MMM")
      heroImage: heroImage {
        fluid(
          maxWidth: 1920
          quality: 60
        ) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      contentBlocks {
        __typename
        ... on Node {
          ... on ContentfulBlockImage {
            id
            videoUrl
            image {
              fluid(
                maxWidth: 1920
                quality: 60
              ) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulBlockText {
            id
            content {
              json
            }
          }
        }
      }
    }
    allContentfulArticle(sort: { fields: publishedDate, order: DESC }, limit: 4) {
      edges {
        node {
          id
          title
          slug
          thumbnail: thumbnail {
            fluid(
              maxWidth: 1000
              quality: 60
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }`;
