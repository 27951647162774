import React from 'react';
import styled from 'styled-components';
import fonts from '../../styles/fonts';
import { Row, Col, Container } from 'styled-bootstrap-grid';
import Circle from '../../resources/images/profile_page/grey-circle.svg';
import Img from 'gatsby-image';
import theme from '../../styles/theme';

const RowStyled = styled(Row)`
    position: relative;
    padding-bottom: ${props => props.filter ? '' : '40px'};
    padding-top: ${props => props.bottom && '70px'};
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-bottom: 105px;
        padding-top: 0;
    }
`;

const ColStyled = styled(Col)`
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    display: ${props => props.desktop ? 'none' : 'flex'};
    @media(min-width:  ${theme.breakpoints.medium}) {
        display: ${props => props.mobile && 'none'};
        display: ${props => props.desktop && 'flex'};
    }
`;

const ArticleTitle = styled.div`
    ${fonts.CircularMedium};
    font-size: 24px;
    line-height: 28px;
    color: ${theme.colors.black};
    padding-right: 60px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -1px;
    }
`;

const ArticleIntro = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 20px;
    color: ${theme.colors.black};
    padding-top: 25px;
    @media(min-width: ${theme.breakpoints.medium}) {
        font-size: 18px;
        line-height: 28px;
    }
`;

const HeroImageContainer = styled.div`
    height: 260px;
    width: 100%;
    @media(min-width: ${theme.breakpoints.medium}) {
        margin: auto;
        height: 680px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        width: calc(100% - 110px);
    }
`;

const HeroImage = styled(Img)`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const ContainerStyled = styled(Container)`
    padding-left: 35px;
    padding-right: 35px;
    box-sizing: border-box;
    padding-top: 120px;
    background: url(${Circle}) no-repeat;
    background-size: 330px;
    background-position-x: calc(100% + 40px);
    background-position-y: 120px;
    @media(min-width: ${theme.breakpoints.medium}) {
        padding-top: 240px;
        box-sizing: border-box;
        background: url(${Circle}) no-repeat;
        background-position-x: 280px;
        background-position-y: 140px;
        background-size: 580px;
    }
    @media(min-width: ${theme.breakpoints.large}) {
        padding-left: 164px;
        padding-right: 164px;
    }
`;

const ArticleHero = (props) => {

    const { title, intro, image, slug } = props;
    return (
        <Container>
            <ContainerStyled>
                <RowStyled>
                    <ColStyled md={8} mdOffset={4} lg={6} lgOffset={6}>
                        <ArticleTitle>{title}</ArticleTitle>
                        <ArticleIntro>{intro}</ArticleIntro>
                    </ColStyled>
                </RowStyled>
            </ContainerStyled>
            <HeroImageContainer>
                <HeroImage fluid={image}/>
            </HeroImageContainer>
        </Container>

    )
}

export default ArticleHero;